import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest, getCategoryListRequest, getTagListRequest, scheduleRequest, getFeatureLocationListRequest, featureRequest } from "../../../api/controller/ArticleController";
import { pageState } from "../../../state/GlobalState";
import { permissionState, userState } from "../../../api/state/AuthState";

export const articleListState = atom({
    key: 'articleListState',
    default: [],
})
export const articleCategoryState = atom({
	key: 'articleCategoryState',
	default:{
		data:[],
		ovf:false,
	}
})
export const articleTagState = atom({
	key: 'articleTagState',
	default:{
		data:[],
		ovf:false,
	}
})
export const articleFeatureLocationState = atom({
	key: 'articleFeatureLocationState',
	default:{
		data:[],
		ovf:false,
	}
})

export const articleFilterStatusValueState = atom({
	key: 'articleFilterStatusValueState',
	default: { label: 'DRAFT', value: 'DRAFT' }
})

export const articleFilterUserValueState = atom({
	key: 'articleFilterUserValueState',
	default: null
})
export const articleFilterUserDefaultState = selector({
	key: 'articleFilterUserDefaultState',
	get: ({ get }) => get(permissionState)?.includes('read-all-articles') ? ({ label: 'All', value: 0 }) : ({ label: 'Mine', value: get(userState)?.id }),
})

export const modalConfigDefault = { size: "lg", wizard: false }

export const articleFilterState = selector({
    key: 'articleFilterState',
    get: ({ get }) => ([
        {
            label: 'Status',
            size: 6,
            offset: get(permissionState)?.includes('read-all-articles') ? 0 : 6,
            required: true,
            type: 'select',
            value: get(articleFilterStatusValueState),
            list: {
                ovf: false,
                data: [
                    { label: 'DRAFT', value: 'DRAFT', permission: 'read-articles' },
                    { label: 'COPY-DESK', value: 'COPY-DESK', permission: 'read-copyDesk' },
                    { label: 'SCHEDULED', value: 'SCHEDULED', permission: 'read-scheduled' },
                    { label: 'PUBLISHED', value: 'PUBLISHED', permission: 'read-published' },
                    { label: 'ARCHIVED', value: 'ARCHIVED', permission: 'read-archived' },
                ].filter((v)=>get(permissionState)?.includes(v.permission)).map(v=>({ label: v.label, value: v.value }))
            }
        },
        {
            label: 'Scope',
            size: 6,
            required: true,
            type: 'select',
            value: get(articleFilterUserValueState) || get(articleFilterUserDefaultState),
            visible: get(permissionState)?.includes('read-all-articles'),
            list: {
                ovf: false,
                data: [
                    { label: 'All', value: 0 },
                    { label: 'Mine', value: get(userState)?.id },
                ].filter((v)=>{
                    return (v.value === 0 && get(permissionState)?.includes('read-all-articles')) || (v.value === get(userState)?.id)
                })
            }
        }
    ]),
    set: ({ set }, e) => {
        if(e.filterLabel === 'Status'){
            set(articleFilterStatusValueState, e.value)
        } else if(e.filterLabel === 'Scope'){
            set(articleFilterUserValueState, e.value)
        }
    }
})


export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest, autoSave: 10, closeOnSubmit: true }, { name: 'Schedule', action: scheduleRequest, closeOnSubmit: true }, { name: 'Feature', action: featureRequest, closeOnSubmit: true } ],
    view: [ { name: 'Default View' } ],
}

export const articleDataStructureState = selector({
    key: 'articleDataStructureState',
    get: ({ get }) => (
        [

            { 
                label: 'Type',
                type: 'select',
                order: { table: 1, form: 1 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0', {enabled:false},{enabled:false}], view: ['same_as:create.0'], 
                table: { maxWidth: 150, customRender: (val, data) => {
                    return <>
                        {data?.data?.is_locked ? <><i className="fa fa-lock" style={data?.data?.editor_id === get(userState).id ? {color: 'green'} : {}}></i>&nbsp;&nbsp;</> : null}
                        {val}
                    </>
                } },
				rootValue: true,
				list: {
					ovf: false,
					data:[
						{ label: 'News', value: 'News' },
						{ label: 'Live Blog', value: 'Live Blog' },
					]
				},
            },
			{ 
                label: 'Title',
                type: 'text',
                order: { table: 2, form: 2 }, 
                table: { dv: true },
                create: [{ enabled: true, size: { xs: 12, md: 6 }, classes: 'dv' }], update: ['same_as:create.0', {enabled:false},{enabled:false}], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Latin Title',
                type: 'text',
                order: { form: 3 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0', {enabled:false},{enabled:false}], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Short Title',
                field: 'display_title',
                type: 'text',
                order: { form: 4 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }, classes: 'dv' }], update: ['same_as:create.0', {enabled:false},{enabled:false}], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Short Description',
                field: 'subtitle',
                type: 'textarea',
                order: { form: 5 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }, classes: 'dv' }], update: ['same_as:create.0', {enabled:false},{enabled:false}], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Categories',
                type: 'repeater',
                order: { form: 6 },
                create: [{ enabled: true, size: { xs: 12, md: 12 }, deleteBtnSize: 2, repeatLabel: false }], update: ['same_as:create.0', {enabled:false},{enabled:false}], view: [{enabled:false}],
                children: [
                    { 
                        label: 'Category',
                        type: 'nestedselect',
                        field: 'select_format',
                        order: { form: 1 }, 
                        create: [{ enabled: true, size: { xs: 12, md: 12 }, attributes: { enableRootSelect: true, enableMidSelect: true } }], update: ['same_as:create.0', {enabled:false},{enabled:false}], view: ['same_as:create.0'], 
                        list: get(articleCategoryState),
                        getRequest: getCategoryListRequest,
                    },
                ]
            },
			{ 
                label: 'Tags',
                type: 'multiselect',
                order: { form: 7 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0', {enabled:false},{enabled:false}], view: ['same_as:create.0'], 
				list: get(articleTagState),
				getRequest: getTagListRequest,
            },
			{ 
                id: "DSF90S7FK",
                label: 'Cover Image',
                field: 'cover_image_id',
                type: 'localmedia',
                order: { form: 8 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0', {enabled:false},{enabled:false}], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Cover Image Caption',
                type: 'text',
                order: { form: 9 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }, classes: 'dv' }], update: ['same_as:create.0', {enabled:false},{enabled:false}], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Is Live',
                type: 'switch',
                order: { form: 10 }, 
                create: [{ enabled: true, size: { xs: 12, md: 4, lg: 3 }  }], update: ['same_as:create.0', {enabled:false},{enabled:false}], view: ['same_as:create.0'], 
				list: ['Yes', 'No'],
            },
			{ 
                label: 'Is Breaking News',
                type: 'switch',
                order: { form: 11 }, 
                create: [{ enabled: true, size: { xs: 12, md: 4, lg: 3 }  }], update: ['same_as:create.0', {enabled:false},{enabled:false}], view: ['same_as:create.0'], 
				list: ['Yes', 'No'],
            },
			{ 
                label: 'Is Developing News',
                type: 'switch',
                order: { form: 12 }, 
                create: [{ enabled: true, size: { xs: 12, md: 4, lg: 3 }  }], update: ['same_as:create.0', {enabled:false},{enabled:false}], view: ['same_as:create.0'], 
				list: ['Yes', 'No'],
            },
			{ 
                label: 'Anonymous',
                type: 'switch',
                order: { form: 12.1 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6, lg: 3 }  }], update: ['same_as:create.0', {enabled:false},{enabled:false}], view: ['same_as:create.0'], 
				list: ['Yes', 'No'],
            },
			{ 
                label: 'Content',
                type: 'tinymce',
                order: { form: 13 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }, simpleMode: true }], update: ['same_as:create.0', {enabled:false},{enabled:false}], view: [{enabled:false}], 
            },
			{ 
                label: 'Live Contents',
                type: 'repeater',
                order: { form: 14 },
                create: [{ enabled: true, size: { xs: 12, md: 12 }, deleteBtnSize: 2, repeatLabel: false, validations: 'hidden_if:Type,!Live Blog' }], update: ['same_as:create.0', {enabled:false},{enabled:false}], view: [{enabled:false}],
                children: [
                    { 
                		label: 'Content',
                		type: 'tinymce',
                		order: { form: 1 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 12 }, simpleMode: true }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            		},
					
                ]
            },
			{ 
                label: 'Remarks',
                type: 'textarea',
                order: { form: 15 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 } , attributes: { maxLength: '225', rows: '3' } }], update: ['same_as:create.0', {enabled:false},{enabled:false}], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Status',
                type: 'text',
                order: { form: 16 }, 
                create: [{ enabled: false }], update: [{ enabled: false }, {enabled:false},{enabled:false}], view: [{ enabled: true, size: { xs: 12, md: 12 } }], 
            },
			{ 
                label: 'Scheduled Time',
                field: 'scheduled_at',
                type: 'datepicker',
                order: { form: 100 }, 
                create: [{ enabled: false }], update: ['same_as:create.0', { enabled: true, size: { xs: 12, md: 12 }, dateFormat: 'YYYY-MM-DD HH:mm', options: { altInput: true, enableTime: true, time_24hr: true, altFormat: 'F j, Y H:i', dateFormat: 'Y-m-d H:i' } },{enabled:false}], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Feature Location',
                type: 'select',
                order: { table: 5, form: 200 }, 
                table: { maxWidth: 180 },
                create: [{ enabled: false }], update: ['same_as:create.0', {enabled:false},{ enabled: true, size: { xs: 12, md: 12 }  }], view: ['same_as:create.0'], 
				list: get(articleFeatureLocationState),
				getRequest: getFeatureLocationListRequest,
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
