
import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import MediaLibrary from "../mediaLibrary";
import { findImageRequest } from "../../../api/controller/MediaController";
import { Loader } from "../Loader";

const LocalMediaInput = ({ value={}, onChange=()=>false, returnFull=false, noNull=false, tags=[], disabled=false, readOnly=false, hidden=false}) => {

    const [selectedID, setSelectedID] = useState(typeof(value) === 'number' && value.length > 0 ? value : "")
    const [selectedMedia, setSelectedMedia] = useState({})
    const [viewModal, setViewModal] = useState(false)
    const [selectedVariant, setSelectedVariant] = useState(null);

    const [libraryVisible, setLibraryVisible] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(value){
            setSelectedID(value)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            if(selectedID){
                const response = await findImageRequest({id: selectedID})
                setSelectedMedia(response?.data || {})
                if(returnFull){
                    onChange(response?.data)
                } else {
                    onChange(selectedID)
                }
            } else {
                if(! noNull){
                    onChange(null)
                }
            }
            setLibraryVisible(false)
            setLoading(false)
        }
        fetchData()
        // eslint-disable-next-line
    }, [selectedID])


    if (hidden) return null;
      

    return (
        <div>
            {! (disabled || readOnly) ? (
                <div className="input-group mb-3">
                    {selectedMedia?.file && <button className="btn btn-primary" type="button" onClick={()=>setViewModal(true)} style={{zIndex: 0}}><i className="mdi mdi-eye"></i></button>}
                    <input style={{cursor: 'pointer'}} type="text" readOnly={true} onClick={()=>setLibraryVisible(true)} value={selectedID ? (selectedMedia?.name || "Loading...") : "Click to Select from Library"} className="form-control" />
                    {selectedID && selectedMedia?.file ? <img onClick={()=>setViewModal(true)} src={(process.env.REACT_APP_STORAGE_TYPE === "external" ? process.env.REACT_APP_STORAGE_URL : process.env.REACT_APP_API_PUBLIC_URL)+selectedMedia?.file} alt={selectedMedia?.name} style={{cursor:'pointer', maxWidth: "50px", maxHeight: "40px", borderTopRightRadius: "5px", borderBottomRightRadius: "5px", border: "1px solid rgba(0, 0, 0, 0.2)"}} /> : null}
                </div>
            ) : 
                (selectedMedia ? <img onClick={()=>setViewModal(true)} style={{maxWidth:"100%", cursor: "pointer"}} src={(process.env.REACT_APP_STORAGE_TYPE === "external" ? process.env.REACT_APP_STORAGE_URL : process.env.REACT_APP_API_PUBLIC_URL)+selectedMedia?.file} alt={selectedMedia?.name} /> : "No Image"
            )}
            
            <Modal isOpen={libraryVisible} toggle={() => setLibraryVisible(false)} size="xl">
                <div className="modal-header">
                    <button type="button" className="close" onClick={() => setLibraryVisible(false)} aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body" style={{minHeight: "70vh"}}>
                    <Loader loading={loading} />
                    <MediaLibrary selectMode={true} selectedID={selectedID} setSelectedID={setSelectedID} tags={tags} />
                </div>
            </Modal>

            
            <Modal size="xl" isOpen={viewModal} toggle={()=>{
                setViewModal(false)
                setSelectedVariant(null)
            }}>
                <div className="modal-header">
                    <button type="button" onClick={() => {
                        setViewModal(false)
                        setSelectedVariant(null)
                    }} className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <React.Fragment>
                    <div id="modalBody" className="modal-body">
                        <div className="text-center">
                            {selectedMedia && selectedMedia?.variants?.map((v,k)=>
                                <button key={k} onClick={()=>setSelectedVariant(v)} className={"btn btn-rounded me-2 mb-2" + (selectedVariant?.variant === v.variant ? " btn-primary" : " btn-secondary")}>{v.variant}</button>
                            )}
                        </div>
                        <hr />
                        <div className="text-center">
                            <img alt={selectedMedia?.caption} className="img-fluid" src={(process.env.REACT_APP_STORAGE_TYPE === 'external' ? process.env.REACT_APP_STORAGE_URL : process.env.REACT_APP_API_PUBLIC_URL)+(selectedVariant ? selectedVariant?.file : selectedMedia?.file)} />
                        </div>
                    </div>
                </React.Fragment>
            </Modal>
        </div>
    )
    
}

export default LocalMediaInput;

