
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import React, { useEffect, useState } from "react";
import { getArticleStatsRequest, getStatsRequest } from "../../api/controller/DashboardController";
import Select from "react-select";
import ReactApexChart from "react-apexcharts";
import { Link } from "react-router-dom"
import { Loader } from "../../custom/components/Loader";
import moment from "moment";

const Home = () => {

    const [loading, setLoading] = useState(false)
    const [type, setType] = useState({ label: 'Today', value: 'Day' })
    const [stats, setStats] = useState({})
    const [articleStats, setArticleStats] = useState({})

    const [lineSeries, setLineSeries] = useState([{ name: "Visitors", data: [] }])
    const [lineOptions, setLineOptions] = useState({
        chart: { toolbar: { show: false } },
        dataLabels: { enabled: false },
        stroke: { curve: "smooth", width: 3 },
        colors: ["#556ee6"],
        xaxis: { categories: [] },
        yaxis: { title: { text: "Visitors" } },
        grid: { borderColor: "#f1f1f1" },
    })

    const [columnSeries, setColumnSeries] = useState([{ name: "Visitors", data: [] }])
    const [columnOptions, setColumnOptions] = useState({
        chart: { toolbar: { show: false } },
        plotOptions: { bar: { horizontal: false, columnWidth: "45%", endingShape: "rounded" } },
        dataLabels: { enabled: false },
        stroke: { show: true, width: 2, colors: ["transparent"] },
        colors: ["#34c38f", "#556ee6", "#f46a6a"],
        xaxis: { categories: [] },
        yaxis: { title: { text: "Visitors" } },
        grid: { borderColor: "#f1f1f1" },
        fill: { opacity: 1 },
    })


    useEffect(() => {
        async function fetchVisits(){
            setLoading(true)
            const response = await getStatsRequest()
            setStats(response)
            setLoading(false)
        }
        fetchVisits()
    }, [])

    useEffect(() => {
        async function fetchStats(){
            setLoading(true)
            const response = await getArticleStatsRequest({ Type: type?.value })
            setArticleStats(response)
            setLoading(false)
        }
        fetchStats()
        // eslint-disable-next-line
    }, [type])

    useEffect(() => {
        if(stats?.daily_visits){
            let labels = []
            let data = []
            Object.keys(stats.daily_visits).forEach(key => {
                labels.push(moment(key).format("Do MMMM"))
                data.push(stats.daily_visits[key])
            });
            setLineSeries([{ name: "Visitors", data: data }])
            setLineOptions({ ...lineOptions, xaxis: { categories: labels } })
        }
        if(stats?.monthly_visits){
            let labels = []
            let data = []
            Object.keys(stats.monthly_visits).forEach(key => {
                labels.push(moment(key+"-01").format("MMMM YYYY"))
                data.push(stats.monthly_visits[key])
            });
            setColumnSeries([{ name: "Visitors", data: data }])
            setColumnOptions({ ...columnOptions, xaxis: { categories: labels } })
        }
        // eslint-disable-next-line
    }, [stats])

    

    return (
        <React.Fragment>
            <div className="page-content" style={{position: 'relative'}}>
                <Loader loading={loading} />
                <Container fluid style={{minHeight:"40vh"}}>
                    <Row>
                        <Col xs={12} md={9}>
                            <h3>Dashboard</h3>
                        </Col>
                        <Col xs={12} md={3}>
                            <Select className="mb-3" value={type} options={[{ value: 'Day', label: 'Today' }, { value: 'Week', label: 'Past Week' }, { value: 'Month', label: 'Past Month' }]} onChange={(e) => setType(e)} />
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col md={3}>
                            <Card className="blog-stats-wid">
                            <CardBody>
                                <div className="d-flex flex-wrap">
                                    <div className="me-3"><p className="mb-2">No. of Articles <i className="text-muted">({type.label})</i></p><h5 className="mb-0">{articleStats?.article_count || "-"}</h5></div>
                                    <div className="avatar-sm ms-auto"><div className="avatar-title bg-light rounded-circle text-primary font-size-20"><i className="bx bxs-book-bookmark"></i></div></div>
                                </div>
                            </CardBody>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card className="blog-stats-wid">
                                <CardBody>
                                    <div className="d-flex flex-wrap">
                                        <div className="me-3"><p className="mb-2">Visits <i className="text-muted">({type.label})</i></p><h5 className="mb-0">{articleStats?.visits || "-"}</h5></div>
                                        <div className="avatar-sm ms-auto"><div className="avatar-title bg-light rounded-circle text-primary font-size-20"><i className="mdi mdi-eye"></i></div></div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card className="blog-stats-wid">
                                <CardBody>
                                    <div className="d-flex flex-wrap">
                                        <div className="me-3"><p className="mb-2">Articles in Copy-Desk <i className="text-muted">(Currently)</i></p><h5 className="mb-0">{stats?.copy_desk_articles || "-"}</h5></div>
                                        <div className="avatar-sm ms-auto"><div className="avatar-title bg-light rounded-circle text-primary font-size-20"><i className="bx bxs-note"></i></div></div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card className="blog-stats-wid">
                                <CardBody>
                                    <div className="d-flex flex-wrap">
                                        <div className="me-3"><p className="mb-2">Pending Comments <i className="text-muted">(Currently)</i></p><h5 className="mb-0">{stats?.pending_comments || "-"}</h5></div>
                                        <div className="avatar-sm ms-auto"><div className="avatar-title bg-light rounded-circle text-primary font-size-20"><i className="bx bxs-message-square-dots"></i></div></div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <Card>
                                <CardBody>
                                    <h5 className="text-muted mb-2">Top Articles <i className="text-muted">({type.label})</i></h5>
                                    <div className="table-responsive">
                                        <table className="table align-middle table-nowrap mb-0">
                                            <thead>
                                            <tr align="right">
                                                <th scope="col">Comments</th>
                                                <th scope="col">Visits</th>
                                                <th scope="col" colSpan="2">Article</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {articleStats?.top_articles?.length === 0 && <tr><td colSpan="4" align="center">No articles found</td></tr>}
                                                {articleStats?.top_articles?.map((article, key) => (
                                                    <tr align="right" key={key}>
                                                        <td><i className="bx bx-comment-dots align-middle me-1"></i>{" "}{article.comments || 0}</td>
                                                        <td><i className="mdi mdi-eye align-middle me-1"></i>{" "}{article.visits || 0}</td>
                                                        <td>
                                                            <h5 style={{ width:"470px"}} dir="rtl" className="d-none d-md-block font-size-13 text-truncate mb-1 dv"><Link to="#" className="text-dark">{article.title}</Link></h5>
                                                            <h5 style={{ width:"150px"}} dir="rtl" className="d-block d-md-none font-size-13 text-truncate mb-1 dv"><Link to="#" className="text-dark">{article.title}</Link></h5>
                                                            <p className="text-muted mb-0">{article.created_at}</p>
                                                        </td>
                                                        <td style={{ width: "100px" }}><img alt={article.title} src={(process.env.REACT_APP_STORAGE_TYPE === "external" ? process.env.REACT_APP_STORAGE_URL : process.env.REACT_APP_API_PUBLIC_URL) + article.image} className="avatar-md h-auto d-block rounded"/></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={12} md={6}>
                            <Card>
                                <CardBody>
                                    <h5 className="text-muted mb-2">Top Authors  <i className="text-muted">({type.label})</i></h5>
                                    <div className="table-responsive">
                                        <table className="table align-middle table-nowrap mb-0">
                                            <thead>
                                            <tr>
                                                <th scope="col">Username</th>
                                                <th scope="col">Article Count</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {articleStats?.top_users?.length === 0 && <tr><td colSpan="4" align="center">No authors found</td></tr>}
                                                {articleStats?.top_users?.map((user, key) => (
                                                    <tr key={key}>
                                                        <td><h5 className="font-size-13 text-truncate mb-1"><Link to="#" className="text-dark">{user.user}</Link></h5></td>
                                                        <td><i className="bx bxs-note align-middle me-1"></i>{" "}{user.article_count || 0}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <h5 className="text-muted mb-2">Daily Visitors</h5>
                                    <ReactApexChart options={lineOptions} series={lineSeries} type="line" height={350} />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <h5 className="text-muted mb-2">Monthly Visitors</h5>
                                    <ReactApexChart options={columnOptions} series={columnSeries} type="bar" height={350} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    
                </Container>
            </div>
        </React.Fragment>
        
    )
    
}

export default Home;

