import http from "../core/http";
const group = 'dashboard';

export const getStatsRequest =  async (data) => {
    return await http.post(`${group}/getStats`, data).then(res => res.data).catch(http.defaultCatch);
}

export const getArticleStatsRequest =  async (data) => {
    return await http.post(`${group}/getArticleStats`, data).then(res => res.data).catch(http.defaultCatch);
}

