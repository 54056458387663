import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest } from "../../../api/controller/CommentController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const commentListState = atom({
    key: 'commentListState',
    default: [],
})
export const commentArticleState = atom({
	key: 'commentArticleState',
	default:{
		data:[],
		ovf:false,
	}
})

export const commentFilterStatusValueState = atom({
	key: 'commentFilterStatusValueState',
	default: { label: 'PENDING', value: 'PENDING' }
})

export const modalConfigDefault = { size: "md", wizard: false }

export const commentFilterState = selector({
    key: 'commentFilterState',
    get: ({ get }) => ([
        {
            label: 'Status',
            size: 6,
            offset: 6,
            required: true,
            type: 'select',
            value: get(commentFilterStatusValueState),
            list: {
                ovf: false,
                data: [
                    { label: 'PENDING', value: 'PENDING' },
                    { label: 'APPROVED', value: 'APPROVED' },
                ]
            }
        }
    ]),
    set: ({ set }, e) => {
        if(e.filterLabel === 'Status'){
            set(commentFilterStatusValueState, e.value)
        }
    }
})


export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const commentDataStructureState = selector({
    key: 'commentDataStructureState',
    get: ({ get }) => (
        [

            { 
                label: 'Article',
                type: 'select',
                subFields: { label: 'title', value: 'id' },
                order: { table: 1, form: 1 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Username',
                type: 'text',
                order: { table: 2, form: 2 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Comment',
                type: 'text',
                order: { form: 3 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Approved',
                type: 'switch',
                order: { table: 3, form: 4 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: ['Yes', 'No'],
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
