import http from "../core/http";
const group = 'media';

export const getDirectoryRequest =  async (data) => {
    return await http.post(`${group}/getDirectory`, data).then(res => res.data).catch(http.defaultCatch);
}
export const searchDirectoryRequest =  async (data) => {
    return await http.post(`${group}/searchDirectory`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getSuggestedImagesRequest =  async (data) => {
    return await http.post(`${group}/getSuggestedImages`, data).then(res => res.data).catch(http.defaultCatch);
}

export const createFolderRequest =  async (data) => {
    return await http.post(`${group}/createFolder`, data).then(res => res.data).catch(http.defaultCatch);
}
export const createImageRequest =  async (data) => {
    return await http.post(`${group}/createImage`, data).then(res => res.data).catch(http.defaultCatch);
}
export const createMultiImageRequest =  async (data) => {
    return await http.post(`${group}/createMultiImage`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getTagListRequest =  async (data) => {
    return await http.post(`${group}/getTagList`, data).then(res => res.data).catch(http.defaultCatch);
}
export const findImageRequest =  async (data) => {
    return await http.post(`${group}/findImage`, data).then(res => res.data).catch(http.defaultCatch);
}

export const renameFolderRequest =  async (data) => {
    return await http.post(`${group}/renameFolder`, data).then(res => res.data).catch(http.defaultCatch);
}
export const updateImageRequest =  async (data) => {
    return await http.post(`${group}/updateImage`, data).then(res => res.data).catch(http.defaultCatch);
}

export const deleteFolderRequest =  async (data) => {
    return await http.post(`${group}/deleteFolder`, data).then(res => res.data).catch(http.defaultCatch);
}
export const deleteImageRequest =  async (data) => {
    return await http.post(`${group}/deleteImage`, data).then(res => res.data).catch(http.defaultCatch);
}