import { atom, selector } from "recoil";
import { createFolderRequest, createImageRequest, createMultiImageRequest, getTagListRequest, renameFolderRequest, updateImageRequest } from "../../../api/controller/MediaController";


export const folderFormModes = {
    create: [ { name: 'Default Create', action: createFolderRequest } ], 
    update: [ { name: 'Default Update', action: renameFolderRequest, closeOnSubmit: true } ],
}
export const imageFormModes = {
    create: [ { name: 'Default Create', action: createImageRequest }, { name: 'Multi Create', action: createMultiImageRequest } ], 
    update: [ { name: 'Default Update', action: updateImageRequest, closeOnSubmit: true } ],
}

export const mediaLibraryTagState = atom({
	key: 'mediaLibraryTagState',
	default:{
		data:[],
		ovf:false,
	}
})

export const folderDataStructureState = selector({
    key: 'folderDataStructureState',
    get: () => (
        [
			{ 
                label: 'Name',
                type: 'text',
                order: { table: 2, form: 2 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },

        ]
    )
})

export const imageDataStructureState = selector({
    key: 'imageDataStructureState',
    get: ({ get }) => (
        [
			{ 
                label: 'Name',
                type: 'text',
                order: { table: 2, form: 2 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 } }, { enabled: false }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'File',
                type: 'file',
                order: { form: 3 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 } }, { enabled: false }], update: [{ enabled: false }], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Dhivehi Caption',
                type: 'text',
                order: { form: 4 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }, classes: 'dv' }, { enabled: false }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'English Caption',
                type: 'text',
                order: { form: 5 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 } }, { enabled: false }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Tags',
                type: 'multiselect',
                order: { form: 6 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 } }, { enabled: false }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: get(mediaLibraryTagState),
				getRequest: getTagListRequest,
            },
			{ 
                label: 'Images',
                type: 'repeater',
                order: { form: 100 },
                create: [{ enabled: false }, { enabled: true, size: { xs: 12, md: 12 }, deleteBtnSize: 2, repeatLabel: true }], update: ['same_as:create.0'], view: ['same_as:create.0'],
                children: [
                    { 
                        label: 'Name',
                        type: 'text',
                        order: { form: 2 }, 
                        create: [{ enabled: false }, { enabled: true, size: { xs: 12, md: 6 } }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
                    },
                    { 
                        label: 'File',
                        type: 'file',
                        order: { form: 3 }, 
                        create: [{ enabled: false }, { enabled: true, size: { xs: 12, md: 6 } }], update: [{ enabled: false }], view: ['same_as:create.0'], 
                    },
                    { 
                        label: 'Dhivehi Caption',
                        type: 'text',
                        order: { form: 4 }, 
                        create: [{ enabled: false }, { enabled: true, size: { xs: 12, md: 12 }, classes: 'dv' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
                    },
                    { 
                        label: 'English Caption',
                        type: 'text',
                        order: { form: 5 }, 
                        create: [{ enabled: false }, { enabled: true, size: { xs: 12, md: 12 } }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
                    },
                    { 
                        label: 'Tags',
                        type: 'multiselect',
                        order: { form: 6 }, 
                        create: [{ enabled: false }, { enabled: true, size: { xs: 12, md: 12 } }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
                        list: get(mediaLibraryTagState),
                        getRequest: getTagListRequest,
                    },
                ]
            }

        ]
    )
})

