import { cloneDeep } from "lodash";
import { atom, selector } from "recoil";
import { authState, permissionState } from "../api/state/AuthState";
import Login from "../app/guest/Login";
import ForgotPassword from "../app/guest/ForgotPassword";
import ChangePassword from "../app/guest/ChangePassword";
import Home from "../app/auth/Home";
import Users from "../app/auth/users"
import Roles from "../app/auth/roles"
import Permissions from "../app/auth/permissions"
import NonAuthLayout from "../components/NonAuthLayout";
import FeatureLocations from "../app/auth/featureLocations"
import Ads from "../app/auth/ads"
import AdLocations from "../app/auth/adLocations"
import Comments from "../app/auth/comments"
import Galleries from "../app/auth/galleries"
import Variants from "../app/auth/variants"
import Articles from "../app/auth/articles"
import Layouts from "../app/auth/layouts"
import Tags from "../app/auth/tags"
import Categories from "../app/auth/categories"
import Media from "../app/auth/media";
import ArticleReports from "../app/auth/ArticleReports";
// import Images from "../app/auth/images"
// import Folders from "../app/auth/folders"



// import HorizontalLayout from "../components/HorizontalLayout";
// import GuestHome from "../app/guest/Home";


const navigation = [
    {
        name: 'Login',
        icon: 'bx bx-lock',
        link: '/login',
        defaultRoute: true,
        component: Login,
        layout: NonAuthLayout,
        middleware: 'guest',
        hidden: true,
        children: [],
    },
    {
        name: 'Forgot Password',
        icon: 'bx bx-lock',
        link: '/forgot-password',
        component: ForgotPassword,
        layout: NonAuthLayout,
        middleware: 'guest',
        hidden: true,
        children: [],
    },
    {
        name: 'Change Password',
        icon: 'bx bx-lock',
        link: '/change-password',
        component: ChangePassword,
        layout: NonAuthLayout,
        middleware: 'guest',
        hidden: true,
        children: [],
    },
	
	// { // Folder Route Begin
	// 	name: 'Folder',
	// 	icon: 'mdi mdi-monitor-dashboard',
	// 	link: '/folders',
	// 	component: Folders,
	// 	middleware: 'auth',
	// 	permission: 'read-folders',
	// 	children: [],
	// }, // Folder Route End
	// { // Image Route Begin
	// 	name: 'Image',
	// 	icon: 'mdi mdi-monitor-dashboard',
	// 	link: '/images',
	// 	component: Images,
	// 	middleware: 'auth',
	// 	permission: 'read-images',
	// 	children: [],
	// }, // Image Route End
    {
        name: '',
        icon: "bx bx-group",
        children: [
            {
                name: 'Main',
                icon: "mdi mdi-newspaper",
                children: [
                    {
                        name: 'Dashboard',
                        icon: 'mdi mdi-monitor-dashboard',
                        link: '/home',
                        defaultRoute: true,
                        component: Home,
                        middleware: 'auth',
                        children: [],
                    },
                    { // Article Route Begin
                        name: 'Article',
                        icon: 'mdi mdi-monitor-dashboard',
                        link: '/articles',
                        component: Articles,
                        middleware: 'auth',
                        permission: 'read-articles',
                        children: [],
                    }, // Article Route End
                    { // Comment Route Begin
                        name: 'Comment',
                        icon: 'mdi mdi-comment',
                        link: '/comments',
                        component: Comments,
                        middleware: 'auth',
                        permission: 'read-comments',
                        children: [],
                    }, // Comment Route End
                    { // Gallery Route Begin
                        name: 'Gallery',
                        icon: 'mdi mdi-monitor-dashboard',
                        link: '/galleries',
                        component: Galleries,
                        middleware: 'auth',
                        permission: 'read-galleries',
                        children: [],
                    }, // Gallery Route End
                    { // Image Route Begin
                        name: 'Media',
                        icon: 'mdi mdi-monitor-dashboard',
                        link: '/media',
                        component: Media,
                        middleware: 'auth',
                        permission: 'read-media',
                        children: [],
                    }, // Image Route End
                    { // Ad Route Begin
                        name: 'Ad',
                        icon: 'mdi mdi-monitor-dashboard',
                        link: '/ads',
                        component: Ads,
                        middleware: 'auth',
                        permission: 'read-ads',
                        children: [],
                    }, // Ad Route End
                    {
                        name: 'Reports',
                        icon: 'mdi mdi-search',
                        link: '/reports',
                        component: ArticleReports,
                        middleware: 'auth',
                        permission: 'read-reports',
                        children: [],
                    },
                ]
            },
            
            {
                name: 'Master',
                icon: "mdi mdi-view-grid",
                children: [
                    { // Category Route Begin
                        name: 'Category',
                        icon: 'mdi mdi-monitor-dashboard',
                        link: '/categories',
                        component: Categories,
                        middleware: 'auth',
                        permission: 'read-categories',
                        children: [],
                    }, // Category Route End
                    { // Tag Route Begin
                        name: 'Tag',
                        icon: 'mdi mdi-monitor-dashboard',
                        link: '/tags',
                        component: Tags,
                        middleware: 'auth',
                        permission: 'read-tags',
                        children: [],
                    }, // Tag Route End
                    { // Layout Route Begin
                        name: 'Layout',
                        icon: 'mdi mdi-monitor-dashboard',
                        link: '/layouts',
                        component: Layouts,
                        middleware: 'auth',
                        permission: 'read-layouts',
                        children: [],
                    }, // Layout Route End
                    { // AdLocation Route Begin
                        name: 'Ad Location',
                        icon: 'mdi mdi-monitor-dashboard',
                        link: '/adLocations',
                        component: AdLocations,
                        middleware: 'auth',
                        permission: 'read-adLocations',
                        children: [],
                    }, // AdLocation Route End
                    { // FeatureLocation Route Begin
                        name: 'Feature Location',
                        icon: 'mdi mdi-monitor-dashboard',
                        link: '/featureLocations',
                        component: FeatureLocations,
                        middleware: 'auth',
                        permission: 'read-featureLocations',
                        children: [],
                    }, // FeatureLocation Route End
                    { // Variant Route Begin
                        name: 'Variant',
                        icon: 'mdi mdi-monitor-dashboard',
                        link: '/variants',
                        component: Variants,
                        middleware: 'auth',
                        permission: 'read-variants',
                        children: [],
                    }, // Variant Route End
                ]
            },
	        // Navigations
            
            {
                name: 'ACL',
                icon: "bx bx-group",
                children: [
                    {
                        name: 'Users',
                        icon: 'bx bx-group',
                        link: '/acl/users',
                        component: Users,
                        middleware: 'auth',
                        permission: 'read-users',
                        children: []
                    },
                    {
                        name: 'Roles',
                        icon: 'mdi mdi-account-key-outline',
                        link: '/acl/roles',
                        component: Roles,
                        middleware: 'auth',
                        permission: 'read-roles',
                        children: []
                    },
                    {
                        name: 'Permissions',
                        icon: 'bx bx-lock-open-alt',
                        link: '/acl/permissions',
                        component: Permissions,
                        middleware: 'auth',
                        permission: 'read-permissions',
                        children: []
                    },
                ]
            },
        ]
    }
];


const megaMenu = [
    // {
    //     name: 'UI Components',
    //     size: 4,
    //     children: [
    //         {
    //             name: 'Lightbox',
    //             link: '/lightbox',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //         {
    //             name: 'Range Slider',
    //             link: '/rangeslider',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //     ]
    // },
    // {
    //     name: 'Applications',
    //     size: 4,
    //     children: [
    //         {
    //             name: 'Ecommerce',
    //             link: '/ecommerce',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //         {
    //             name: 'Calendar',
    //             link: '/calendar',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //     ]
    // },
    // {
    //     name: 'Extra Pages',
    //     size: 4,
    //     children: [
    //         {
    //             name: 'Maintenance',
    //             link: '/maintenance',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //         {
    //             name: 'Coming Soon',
    //             link: '/comingsoon',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //     ]
    // }
]

const rawNavigationState = atom({
    key: 'rawNavigationState',
    default: navigation,
});


const rawMegaMenuState = atom({
    key: 'rawMegaMenuState',
    default: megaMenu,
});





export const navigationState = selector({
    key: 'navigationState',
    get: ({ get }) => {
        let tempNavigation = cloneDeep(get(rawNavigationState))
        const isAuth = get(authState)
        const permissions = get(permissionState)
        const parseModule = (nav) => {
            let returnVal = null
            if(nav.children.length > 0){
                if((isAuth && nav.middleware === "auth" && (!nav.permission || permissions.includes(nav.permission))) || (!isAuth && (nav.middleware === "guest" )) || !nav.middleware){
                    let tempChildren = []
                    nav.children.forEach((child)=>{
                        let temp = parseModule(child)
                        if(temp){
                            tempChildren = [...tempChildren, temp]
                        }
                    })
                    returnVal = {...nav, children: tempChildren}
                }
            } else {
                if((isAuth && nav.middleware === "auth" && (!nav.permission || permissions.includes(nav.permission))) || (!isAuth && (nav.middleware === "guest")) || !nav.middleware){
                    returnVal = nav
                }
            }
            return returnVal
        }
        // Remove Nav Items based on auth and permissions
        tempNavigation = tempNavigation.map((header)=>parseModule(header)).filter(n=>n!==null);
        // Remove Empty Parents
        const filterChildren = (nav) => {
            if(nav.children.filter(c=>c.children.length > 0).length > 0){
                nav.children = nav.children.map((child)=>{
                    child = filterChildren(child)
                    return child
                })
            } else {
                nav.children = nav.children.filter(c=>(c.children.length > 0 || c.component))
            }
            return nav
        }
        tempNavigation = tempNavigation.map((header)=>filterChildren(header));
        // Remove Empty Modules
        tempNavigation = tempNavigation.filter(header=>(header.children.length > 0 || header.component))
        return tempNavigation;
    }
})

export const megaMenuState = selector({
    key: 'megaMenuState',
    get: ({ get }) => {
        let tempMegaMenu = cloneDeep(get(rawMegaMenuState))
        const isAuth = get(authState)
        const permissions = get(permissionState)
        tempMegaMenu = tempMegaMenu.map((header)=>{
            header.children = header.children.filter(c=>(isAuth && c.middleware === "auth" && (!c.permission || permissions.includes(c.permission))) || (!isAuth && (c.middleware === "guest")) || !c.middleware)
            return header
        }).filter(header=>header.children.length > 0);
        return tempMegaMenu;
    }
})
