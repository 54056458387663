
import notify from './Notify';


export const handleResponse = (response, onlyFails=false) => {
    
    if(response?.Status === "error"){
        if(response.payload?.errors){
          Object.keys(response.payload.errors).forEach(function (key){
            response.payload.errors[key].forEach((val)=>{
              notify({ status: 'error', message: val });
            })
          })
        } else {
          notify({ status: 'error', message: response.Message });
        }
    } else if(response?.Status ==='success' && !onlyFails) {
        notify({ status: 'success', message: response.Message });
    }
    
}


export const handleBlobErrors = (response) => {
  if(response?.Status === "error"){
    let fr = new FileReader();
    fr.onload = function(){
        let data = JSON.parse(fr.result);
        if(data?.errors){
          Object.keys(data.errors).forEach(function (key){
            data.errors[key].forEach((val)=>{
              notify({ status: 'error', message: val });
            })
          })
        } else if(data?.Message){
          notify({ status: 'error', message: data.Message });
        }
    }
    fr.readAsText(response.payload);
  }
}

export const handleBlobResponse = (response, name) => {
  if(response && response?.Status === "error"){
    if(response.payload && response.payload instanceof Blob){
        handleBlobErrors(response);
    }
  } else if(response) {
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response]));
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
  }
}