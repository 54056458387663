import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest, getParentListRequest } from "../../../api/controller/CategoryController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const categoryListState = atom({
    key: 'categoryListState',
    default: [],
})
export const categoryParentState = atom({
	key: 'categoryParentState',
	default:{
		data:[],
		ovf:false,
	}
})

export const modalConfigDefault = { size: "md", wizard: false }

export const categoryFilterState = selector({
    key: 'categoryFilterState',
    get: () => ([])
})


export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const categoryDataStructureState = selector({
    key: 'categoryDataStructureState',
    get: ({ get }) => (
        [

            { 
                label: 'Parent',
                type: 'nestedselect',
                order: { table: 1, form: 1 }, 
				table: { editable: true },
                create: [{ enabled: true, size: { xs: 12, md: 12 }, attributes: { enableRootSelect: true, enableMidSelect: true } }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: get(categoryParentState),
				getRequest: getParentListRequest,
            },
			{ 
                label: 'Name',
                type: 'text',
                order: { table: 2, form: 2 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 } }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Dhivehi Name',
                type: 'text',
                order: { table: 3, form: 3 }, 
                table: { dv: true },
                create: [{ enabled: true, size: { xs: 12, md: 12 }, classes: 'dv' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Active',
                type: 'switch',
                order: { table: 4, form: 4 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }, default: true }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
