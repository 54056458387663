import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest, getAdLocationListRequest } from "../../../api/controller/AdController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const adListState = atom({
    key: 'adListState',
    default: [],
})
export const adAdLocationState = atom({
	key: 'adAdLocationState',
	default:{
		data:[],
		ovf:false,
	}
})

export const modalConfigDefault = { size: "lg", wizard: false }

export const adFilterState = selector({
    key: 'adFilterState',
    get: () => ([]),
})


export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const adDataStructureState = selector({
    key: 'adDataStructureState',
    get: ({ get }) => (
        [
			{ 
                label: 'Ad Location',
                type: 'select',
                order: { table: 2, form: 2 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: get(adAdLocationState),
				getRequest: getAdLocationListRequest,
            },
			{ 
                label: 'Sponsor',
                type: 'text',
                order: { form: 3 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Image',
                type: 'file',
                order: { form: 4 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Indefinitely',
                type: 'switch',
                order: { table: 3.5, form: 5 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: ['Yes', 'No'],
            },
			{ 
                label: 'Start Date',
                type: 'datepicker',
                order: { table: 4, form: 6 }, 
				table: { dateFormat: 'MMM Do, YYYY', postDateFormat:'YYYY-MM-DD' },
                create: [{ enabled: true, size: { xs: 12, md: 6 } , dateFormat: 'YYYY-MM-DD', options: { altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d' }, validations: 'hidden_if:Indefinitely,!0' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'End Date',
                type: 'datepicker',
                order: { table: 5, form: 7 }, 
				table: { dateFormat: 'MMM Do, YYYY', postDateFormat:'YYYY-MM-DD' },
                create: [{ enabled: true, size: { xs: 12, md: 6 } , dateFormat: 'YYYY-MM-DD', options: { altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d' }, validations: 'hidden_if:Indefinitely,!0' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Active',
                type: 'switch',
                order: { table: 6, form: 8 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: ['Yes', 'No'],
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
