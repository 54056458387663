import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest, getTagListRequest } from "../../../api/controller/GalleryController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";
import { getFile } from "../../../custom/helpers/Helpers";

export const galleryListState = atom({
    key: 'galleryListState',
    default: [],
})

export const galleryTagState = atom({
	key: 'galleryTagState',
	default:{
		data:[],
		ovf:false,
	}
})

export const modalConfigDefault = { size: "md", wizard: false }

export const galleryFilterState = selector({
    key: 'galleryFilterState',
    get: () => ([])
})


export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const galleryDataStructureState = selector({
    key: 'galleryDataStructureState',
    get: ({ get }) => (
        [

			{ 
                label: 'Thumbnail',
                type: 'file',
                order: { table: 0.1 }, 
                table: { customRender: (value, params) => (
                    <img src={getFile(value)} height="65px" alt="user" style={{borderRadius:"20%", border: "1px solid rgba(0,0,0,0.25)", height: "50px"}} />
                ), maxWidth: 150 },
                create: [{ enabled: false }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
            { 
                label: 'Title',
                type: 'text',
                order: { table: 1, form: 1 }, 
                table: { dv: true },
                create: [{ enabled: true, size: { xs: 12, md: 6 }, classes: 'dv' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Latin Title',
                type: 'text',
                order: { table: 2, form: 2 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Description',
                type: 'textarea',
                order: { form: 3 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 } , attributes: { maxLength: '225', rows: '3' } }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Featured',
                type: 'switch',
                order: { form: 4 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 } }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Tags',
                type: 'multiselect',
                order: { form: 5 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: get(galleryTagState),
				getRequest: getTagListRequest,
            },
			{ 
                label: 'Gallery Images',
                type: 'repeater',
                order: { form: 6 },
                create: [{ enabled: true, size: { xs: 12, md: 12 }, deleteBtnSize: 2, repeatLabel: false }], update: ['same_as:create.0'], view: ['same_as:create.0'],
                children: [
                    { 
                        id: "555",
                		label: 'Image',
                        field: 'image_id',
                		type: 'localmedia',
                		order: { form: 1 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 3 } }], update: ['same_as:create.0'], view: [{ enabled: true, size: { xs: 12, md: 6 } }], 
            		},
					{ 
                		label: 'Caption',
                		type: 'text',
                		order: { form: 2 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 6 }, classes: 'dv' }], update: ['same_as:create.0'], view: [{ enabled: true, size: { xs: 12, md: 4 }  }], 
            		},
					{ 
                		label: 'Order',
                		type: 'number',
                		order: { form: 3 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 3 }  }], update: ['same_as:create.0'], view: [{ enabled: true, size: { xs: 12, md: 2 }  }], 
            		},
					
                ]
            },
			{ 
                label: 'Status',
                type: 'text',
                order: { table: 5 }, 
                table: { maxWidth: 150 },
                create: [{ enabled: false }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
