import React, { useEffect, useState } from 'react';

import { Alert, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Link, withRouter } from 'react-router-dom';
// import { AvField, AvForm } from 'availity-reactstrap-validation';

// import images
import logo from "../../assets/images/logo192.png";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { loginErrorState } from '../../state/ErrorState';
import { loginRequest, verifyLoginRequest } from '../../api/controller/AuthController'
import { passwordResetHashState, otpReferenceState } from '../../api/state/AuthState';
import { themeTypeState } from '../../state/GlobalState';
import { Loader } from '../../custom/components/Loader';
import CarouselPage from '../../pages/AuthenticationInner/CarouselPage';

const Login = ({history,handleAuthResponse}) =>  {
    const [error, setError] = useRecoilState(loginErrorState)
    const [submitDisabled, setSubmitDisabled] = useState(false)
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('authUser')))
    const [rememberMe, setRememberMe] = useState(false)
    const setPasswordResetHash = useSetRecoilState(passwordResetHashState)
    const [otpReference, setOtpReference] = useRecoilState(otpReferenceState)
    const themeType = useRecoilValue(themeTypeState)
    const [form, setForm] = useState({
        email: '',
        password: '',
        OTP: '',
    })

    // handleValidSubmit
    const handleValidSubmit = async (event) => {
        event.preventDefault()
        setSubmitDisabled(true)
        let response = null
        if(otpReference === null){
            response = await loginRequest({email: user ? user.email : form.email, password: form.password})
        } else {
            response = await verifyLoginRequest({ reference: otpReference, otp: form.OTP })
        }
        
        if(response.decodedToken){
            handleAuthResponse(response, response.encodedToken, rememberMe, true)
        } else if(response.scp){
            localStorage.removeItem('authToken')
            setPasswordResetHash(response.hash)
            history.push('/change-password');
        } else if(response.otp){
            localStorage.removeItem('authToken')
            setOtpReference(response.Reference)
            setSubmitDisabled(false)
        } else if (response.message){
            setError(response.message)
            setSubmitDisabled(false)
        } else {
            setError(response.Message)
            setSubmitDisabled(false)
        }
    }

    useEffect(() => {
        setError("");
    }, [setError])

    return (
        <React.Fragment>
            <Container fluid className="p-0">
            
            <Row className="g-0">
                <CarouselPage />
    
                <Col xl={3}>
                <Loader loading={submitDisabled} transparent />
                <div className="auth-full-page-content p-md-5 p-4 bg-black text-white">
                    <div className="w-100">
                    <div className="d-flex flex-column h-100">
                        <div className="mb-4 mb-md-5">
                        <Link to="/dashboard" className="d-block auth-logo"></Link>
                        </div>
                        <div className="my-auto">
                        <div className="pb-3 text-center">
                            <img src={logo} className="mb-5" height="120px" alt="logo" />
                            {/* <h5 className="text-primary">News Admin</h5> */}
                            <p>Sign in to continue.</p>
                        </div>
    
                        <div className="">
                            <Form className="form-horizontal" onSubmit={handleValidSubmit}>
                            {error ? <Alert color="danger">{error}</Alert> : null}
                            {otpReference === null ? (
                                <>
                                {user ? (
                                    <div className="user-thumb text-center mb-4 text-white">
                                        <img src={user.avatar} className="rounded-circle img-thumbnail avatar-md" alt="thumbnail" />
                                        <h5 className={"font-size-15 mt-3 text-muted dv"}>{user.name}</h5>
                                    </div>
                                ) : (
                                    <FormGroup>
                                    <Label style={{ color: "#FFF" }}>Email</Label>
                                    <Input style={{ caretColor: "black", border: "1px solid #ced4da", color: "#495057" }} value={form.email} onChange={(e) => setForm({ ...form, email: e.target.value })} name="email" label="Email" className="form-control bg-white" placeholder="Enter email" type="email" required />
                                    </FormGroup>
                                )}
    
                                <FormGroup>
                                    <Label style={{ color: "#FFF" }}>Password</Label>
                                    <Input style={{ caretColor: "black", border: "1px solid #ced4da", color: "#495057" }} value={form.password} onChange={(e) => setForm({ ...form, password: e.target.value })} name="password" label="Password" type="password" className="form-control bg-white" required placeholder="Enter Password" />
                                </FormGroup>
                                {!user ? (
                                    <div className="custom-control custom-checkbox" onClick={() => setRememberMe(!rememberMe)}>
                                        <Input type="checkbox" className="custom-control-input" onChange={() => false} checked={rememberMe} />
                                        <Label style={{ color: "#FFF" }} className="custom-control-label" htmlFor="customControlInline">{" "}&nbsp;&nbsp;Remember me</Label>
                                    </div>
                                ) : null}
                                </>
                            ) : (
                                <FormGroup>
                                <Label>OTP</Label>
                                <Input value={form.OTP} onChange={(e) => setForm({ ...form, OTP: e.target.value })} name="OTP" label="We sent an OTP via text" className="form-control" placeholder="Enter OTP" type="number" required />
                                </FormGroup>
                            )}
    
                            <div className="mt-4">
                                <button disabled={submitDisabled} className="btn btn-primary btn-block waves-effect waves-light w-100" type="submit">{otpReference === null ? user ? "Unlock" : "Login" : "Verify"}</button>
                            </div>
                            {user && <p style={{ color: "#495057" }} className="mt-3 mb-3" align="center">- OR -</p>}
                            <div className="mt-2">
                                {user ? (
                                <Link onClick={() => { localStorage.removeItem("authUser");setUser(null); }} to="/login" className={"font-weight-medium " + (themeType === "dark" ? "text-info" : "text-primary")}>
                                    <button className="btn btn-light btn-block waves-effect waves-light w-100" type="button">Not you? return to Sign-In</button>
                                </Link>
                                ) : null}
                            </div>
    
                            <div className="mt-4 text-center">
                                {otpReference === null ? (
                                    null // <Link to="/forgot-password" className={"text-muted"}><i className="mdi mdi-lock mr-1"></i> Forgot your password?</Link>
                                ) : (
                                <p>Go back to{" "}<span style={{ cursor: "pointer" }} className={"font-weight-medium text-primary"} onClick={() => { localStorage.removeItem("authUser"); setUser(null); setOtpReference(null); }}>Login</span></p>
                                )}
                            </div>
                            </Form>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </Col>
            </Row>
            </Container>
        </React.Fragment>
    );
}

export default withRouter(Login);
