import React, { useEffect, useState } from 'react';
import { Col, Row, Input } from 'reactstrap';
import { cloneDeep, } from 'lodash';
import "flatpickr/dist/themes/material_blue.css"
import classnames from 'classnames';
import { isEqual } from 'lodash';

const compareProps = (p,c) => {
    return isEqual(p, c)
}

export const NestedSelect = React.memo(({ 
    list=[], 
    defaultValue=null, 
    disabled=false, 
    readOnly=false, 
    hidden=false, 
    onChange= ()=>false, 
    enableRootSelect=false, 
    enableMidSelect=true, 
    dynamicInput=null, 
    activeTree=[],
    setActiveTree=()=>false,
    keys = {value: "id", label: "name", nestingIn: ["children"], nestingOut: ["parents"] } ,
    viewMode = false,
}) => {
  
  const [UniqueKeys] = useState([Math.random(), Math.random()])
  const [showSelect, setShowSelect] = useState(false)
//   const [activeTree, setActiveTree] = useState([])
  const [activeList, setActiveList] = useState([])
  const [selected, setSelected] = useState({
    value: null,
    label: null,
  })
  const [searchQuery, setSearchQuery] = useState("")
  const [searchResults, setSearchResults] = useState([])
  const [myKeys] = useState(keys)

//   useEffect(()=>{
//     setActiveTree([])
//   }, [list, setActiveTree])

useEffect(()=>{
    onChange("", dynamicInput)
    setSelected({value: null, label: null})
    setActiveTree([], dynamicInput)
    setActiveList([])
    setSearchQuery("")
    setSearchResults([])
    setShowSelect(false)
    // eslint-disable-next-line
}, [list])
  
  useEffect(()=>{
        onChange(selected.value, dynamicInput)
        // eslint-disable-next-line
  }, [selected])

  useEffect(() => {
    if(defaultValue){
        let temp = defaultValue
        let tempTree = [temp]
        let count = 0
        while(Boolean(temp[myKeys.nestingOut[count] || myKeys.nestingOut[myKeys.nestingOut.length-1]])){
            temp = temp[myKeys.nestingOut[count] || myKeys.nestingOut[myKeys.nestingOut.length-1]];
            tempTree.push(temp)
            count++
        }
        tempTree.reverse()
        let tempLabel = tempTree.map((t)=>(
            t[myKeys.label] + " > "
        )).join("").slice(0,-3)
        tempTree.pop()
        // tempTree.shift()
        setActiveTree(tempTree, dynamicInput)
        setSelected({value: defaultValue[myKeys.value], label: tempLabel})
    }
    // eslint-disable-next-line
  }, [defaultValue, list, myKeys, setActiveTree])

  useEffect(() => {
    if(activeTree.length === 0){
      setActiveList(list)
    } else {
      let temp = cloneDeep(list)
      activeTree.forEach((t,k)=>{
            temp = temp.filter((c)=>parseInt(c[myKeys.value]) === parseInt(t[myKeys.value]));
            if(temp && temp[0] && temp[0][myKeys.nestingIn[k] || myKeys.nestingIn[myKeys.nestingIn.length-1]]){
                temp = temp[0][myKeys.nestingIn[k] || myKeys.nestingIn[myKeys.nestingIn.length-1]]
            }
      })
      setActiveList(temp)
    }
  }, [activeTree, list, myKeys, setActiveTree])

  useEffect(()=>{
      if(document.getElementById(UniqueKeys[1])){
        document.getElementById(UniqueKeys[1]).scrollTop = 0;
      }
  }, [activeList, UniqueKeys])

  const changeValue = (category) => {
    if(isSelectable(category)){
        let tempLabel = activeTree.map((t)=>(
            t[myKeys.label] + " > "
        )).join("") + category[myKeys.label]
        setSelected({value: category[myKeys.value] , label: tempLabel})
        setShowSelect(false)
    } else {
        setActiveTree([...activeTree, category], dynamicInput)
    }
  }
  
  const changeValueSearch = (row) => {
        setSelected({value: row.value , label: row.label})
        setActiveTree(row.tree, dynamicInput)
        setShowSelect(false)
  }

  useEffect(() => {
    window.onmousedown = function(event){
      if (!document.getElementById(UniqueKeys[0])?.contains(event.target)){
        setShowSelect(false)
      }
    };
  }, [UniqueKeys])

  const getNextList = (item) => (
    item[myKeys.nestingIn[activeTree.length] || myKeys.nestingIn[myKeys.nestingIn.length-1]] || { length: 0 }
  )

  useEffect(() => {
    if(!showSelect){
        setSearchQuery("")
    }
  }, [showSelect])

  useEffect(() => {
    const query = searchQuery.trim().toUpperCase().split(" ")
    if(query.length > 0 && query[0] !== ""){
        const search = (arr, tree=[]) => {
            let results = []
            arr.forEach((item)=>{
                let match = true
                query.forEach((q)=>{
                    if((!((item[myKeys.label].toUpperCase()).indexOf(q) > -1)) || (tree.length === 0 && !enableRootSelect)){
                        match = false;
                    }
                })
                if(match){
                    let tempLabel = tree.map((t)=>(
                        t[myKeys.label] + " > "
                    )).join("")
                    results.push({value: item[myKeys.value], label: (tempLabel + item[myKeys.label]), header: tempLabel.slice(0,-3), item: item[myKeys.label], tree: tree})
                }
                if(item[myKeys.nestingIn[tree.length] || myKeys.nestingIn[myKeys.nestingIn.length-1]]?.length > 0){
                    results = [...results, ...search(item[myKeys.nestingIn[tree.length] || myKeys.nestingIn[myKeys.nestingIn.length-1]], [...tree, item])]
                }
            })
            return results;
        }
        setSearchResults(search(list))
    } else {
        setSearchResults([])
    }
  }, [searchQuery, list, myKeys, enableRootSelect])

  const isSelectable = (category)  => Boolean(activeTree.length === 0 && enableRootSelect) || (activeTree.length > 0 && getNextList(category).length === 0) || (activeTree.length > 0 && getNextList(category).length > 0 && enableMidSelect)
  
  return (
    !hidden ? <div id={UniqueKeys[0]}>
        {viewMode ? <p>{selected?.label}</p>  : <Input style={disabled ? {backgroundColor: "hsl(0,0%,95%)"} : {}} type="text" disabled={disabled} readOnly={readOnly} value={searchQuery} title={selected?.label} onChange={(e)=>{if(!showSelect){setShowSelect(true)};setSearchQuery(e.target.value)}} placeholder={selected?.label || "Select..."} onClick={()=>setShowSelect(!showSelect)} ></Input>}
        {!disabled && !readOnly && showSelect ? (
            <div className="nested-list-container">
                <ul id={UniqueKeys[1]} className="nested-list">
                    {list.length > 0 ? searchResults.length > 500 || searchQuery === "" ? (
                        <React.Fragment>
                            {activeTree.length > 0 ? (
                                <li className={"nested-list-item nested-list-header pt-3 pb-1"}>
                                    <Row className="ml-0 mr-0">
                                        <Col xs={12}>
                                            <p>
                                                <i className="nested-list-header-item p-2 mdi mdi-arrow-left" onClick={()=>setActiveTree(activeTree.slice(0,-1), dynamicInput)}></i>
                                                {activeTree.map((t,k)=>(
                                                    <React.Fragment key={k}>
                                                        <span className="nested-list-header-item p-2" onClick={()=>setActiveTree(activeTree.slice(0,k+1), dynamicInput)}>{t[myKeys.label]}</span>
                                                        {activeTree.length-1 !== k ? <span style={{verticalAlign:"middle"}}><i className="mdi mdi-chevron-right font-size-20"></i></span> : null}
                                                    </React.Fragment>
                                                ))}
                                            </p>
                                        </Col>
                                    </Row>
                                </li>
                            ) : null}
                            <div className="nested-list-item-container">
                                {activeList.map((c,k)=>(
                                    <li key={k} className={classnames("nested-list-item", { "nested-root": !isSelectable(c) })} value={c[myKeys.value]}>
                                        <Row className={classnames("ml-0 mr-0", { "nested-list-name-highlight": Boolean(getNextList(c).length === 0) })}  onClick={()=>getNextList(c).length === 0 ? changeValue(c) : false}>
                                            <Col className={classnames("p-2", "nested-list-name", {"pl-3": !isSelectable(c)}, { "nested-list-name-highlight": isSelectable(c) })} xs={10} md={10} onClick={()=>changeValue(c)}>
                                                <Row className="ml-0 mr-0">
                                                    {(isSelectable(c)) ? (
                                                        <Col xs={2} md={1}>
                                                            <i className={"mdi mdi-checkbox-blank-circle"+(parseInt(c[myKeys.value]) === parseInt(selected?.value) ? "" : "-outline")+" font-size-24 text-muted"}></i>
                                                        </Col>
                                                    ) : null}
                                                    <Col xs={(isSelectable(c)) ? 10 : 12} md={(isSelectable(c)) ? 11: 12}>
                                                        <p style={getNextList(c).length === 0 ? { "lineHeight": "36px" } : null}>{c[myKeys.label]}</p>
                                                        {getNextList(c).length > 0 ? <small className="text-muted">{getNextList(c).length} sub categor{getNextList(c).length === 1 ? "y" : "ies"}</small> : null}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            {getNextList(c).length > 0 ? (
                                                <Col className={classnames("p-2", "pl-3", "nested-list-arrow", { "nested-list-arrow-highlight": Boolean((isSelectable(c))) })} onClick={()=>setActiveTree([...activeTree, c], dynamicInput)} xs={2} md={2} align="center">
                                                    <i className="mdi mdi-chevron-right font-size-24 text-muted"></i>
                                                </Col>
                                            ) : <Col xs={2} md={2}></Col>}
                                        </Row>
                                    </li>
                                ))}
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <li className="nested-list-item nested-list-item-container">
                                {searchResults.length > 0 ? searchResults.map((row,key)=>(
                                    <React.Fragment key={key}>
                                        {key.length === 0 || searchResults[key-1]?.header !== row.header ? (
                                            <Row className="ml-0 mr-0">
                                                <Col className="pt-2 pl-3 pb-0" xs={12}>
                                                    <strong>{row.header}</strong>
                                                </Col>
                                            </Row>
                                        ) : null}
                                        <Row className="ml-0 mr-0 nested-list-search" onClick={()=>changeValueSearch(row)}>
                                            <Col className="p-2 pl-3" xs={12}>
                                                <Row className="ml-0 mr-0">
                                                    <Col xs={2} md={1} className="pr-0">
                                                        <i className={"mdi mdi-checkbox-blank-circle-outline font-size-24 text-muted"}></i>
                                                    </Col>
                                                    <Col xs={10} md={11} className="pl-0">
                                                        <p style={{ "lineHeight": "36px" }}>{row.item}</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                )) : (
                                    <React.Fragment>
                                        <Row className="ml-0 mr-0 nested-list-search">
                                            <Col className="p-2 pl-3" xs={12}>
                                                <Row className="ml-0 mr-0">
                                                    <Col xs={12} className="pl-0">
                                                        <p style={{ lineHeight: "36px", textAlign: "center" }}>No Results found!</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                )}
                            </li>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <li className="nested-list-item nested-list-item-container">
                                <React.Fragment>
                                    <Row className="ml-0 mr-0 nested-list-search">
                                        <Col className="p-2 pl-3" xs={12}>
                                            <Row className="ml-0 mr-0">
                                                <Col xs={12} className="pl-0">
                                                    <p style={{ lineHeight: "36px", textAlign: "center" }}>No Items in list!</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            </li>
                        </React.Fragment>
                    )}
                </ul>
            </div>
        ) : null }
    </div> : null
  );
}, compareProps);

NestedSelect.displayName = 'NestedSelect';
// export default NestedSelect;








