import React from "react";
import MediaLibrary from "../../../custom/components/mediaLibrary";



const Media = () => {


    return (
        <React.Fragment>
            <div className="page-content">
                <MediaLibrary />
            </div>
        </React.Fragment>
        
    )
    
}

export default Media;

