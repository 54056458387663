
import { Card, CardBody, Col, DropdownMenu, DropdownToggle, Form, Modal, Row, UncontrolledDropdown } from "reactstrap";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom"
import { deleteFolderRequest, deleteImageRequest, getDirectoryRequest, getSuggestedImagesRequest, getTagListRequest, searchDirectoryRequest } from "../../../api/controller/MediaController";
import { folderDataStructureState, folderFormModes, imageDataStructureState, imageFormModes, mediaLibraryTagState } from "./state";
import { DynamicModal } from "../DynamicModal";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { lastAPIActionTimeState } from "../../../state/GlobalState";
import { ConfirmDialog } from "../ConfirmDialog";
import { Loader } from "../Loader";
import notify from "../../helpers/Notify";

const MediaLibrary = ({ selectMode=false, selectedID=null, setSelectedID=()=>false, tags=[] }) => {

    const selects = [
        { model: 'Tag', getFn: getTagListRequest, setFn: useSetRecoilState(mediaLibraryTagState), ref: useRef([]), keys: { label: 'name', value: 'id' } },
    ]

    const lastAPIActionTime = useRecoilValue(lastAPIActionTimeState)

    const [loading, setLoading] = useState(true);

    const [folders, setFolders] = useState([]);
    const [images, setImages] = useState([]);
    const [suggestedImages, setSuggestedImages] = useState([]);

    const [selectedFolder, setSelectedFolder] = useState(null); // null means root folder

    const folderDS = useRecoilValue(folderDataStructureState)
    const imageDS = useRecoilValue(imageDataStructureState)
    const [folderModalConfig, setFolderModalConfig] = useState({visible: false, header: "Folder", type: null, typeKey: null, data: {}, size: "md", wizard: false, parentKey: "Parent"})
    const [imageModalConfig, setImageModalConfig] = useState({visible: false, header: "Image", type: null, typeKey: null, data: {}, size: "md", wizard: false, parentKey: "Parent"})
    
	
    const [confirmModalVisible, setConfirmModalVisible] = useState(false)
    const [confirmID, setConfirmID] = useState(null);
    const [confirmParams, setConfirmParams] = useState({});
    const [confirmConfig, setConfirmConfig] = useState({ fn: null, title: null, body: null });

    const toggleConfirmModal = ({row={}, params={}, request=null, title=null, body=null}) => {
      setConfirmID(row ? row.id : null)
      setConfirmParams(params)
      setConfirmConfig({ fn: request, title: title, body: body })
      setConfirmModalVisible(! confirmModalVisible)
    }
    
    const [searchValue, setSearchValue] = useState("");

    const [imageViewModalVisible, setImageViewModalVisible] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedVariant, setSelectedVariant] = useState(null);
    const toggleImageViewModal = ({row}) => {
        if(row){
            setSelectedImage(row);
        } else {
            setSelectedImage(null);
            setSelectedVariant(null);
        }
        setImageViewModalVisible(!imageViewModalVisible);
    }

    const toggleFolderModal = async({row={}, mode=null, modeKey=0, size="md"}) => row && setFolderModalConfig({ ...folderModalConfig, visible: !folderModalConfig.visible, type: typeof(mode) === 'string' ? mode : null, typeKey: modeKey, data: row, size: size || folderModalConfig.size })

    const toggleImageModal = async({row={}, mode=null, modeKey=0, size="md"}) => row && setImageModalConfig({ ...imageModalConfig, visible: !imageModalConfig.visible, type: typeof(mode) === 'string' ? mode : null, typeKey: modeKey, data: row, size: size || imageModalConfig.size })
    
    
  useEffect(() => {
    async function fetchSelectData(){
      await Promise.all(selects.map(async(select)=>{
        if(! (select.persist && select.read?.length > 0)){
          const response = await select.getFn();
          const setFnRef = data => {
            select.ref.current = data
            select.setFn(data)
          }
          if(response && response.Status !== 'error'){	
            setFnRef({ ovf: response.ovf, data: (select.exactValue ? response.data : response.data?.map((val)=>({ label: val[select.keys.label || "name"], value: val[select.keys.value || "id"] }))) })	
          } else {	
            notify({ status: 'error', message: response.Message });	
          }
        }
      }))
    }
    async function fetchSuggestedImages(){
        if(selectMode && tags.length > 0){
            const response = await getSuggestedImagesRequest({ Tags: tags });
            setSuggestedImages(response?.data || [])
        }
    }
    fetchSelectData();
    fetchSuggestedImages();
    return () => {
      selects.filter(s=>!(s.persist && s.read?.length > 0)).forEach((s)=>{
        s.ref.current = []
        s.setFn([])
      })
    }
    // eslint-disable-next-line
  }, [])

    useEffect(() => {
        async function getFolders(){
            setLoading(true);
            setSearchValue("")
            const response = await getDirectoryRequest({ Parent: selectedFolder?.id, SelectMode: selectMode });
            setFolders(response?.folders || []);
            setImages(response?.images || []);
            setLoading(false);
        }
        getFolders();
        // eslint-disable-next-line
    }, [lastAPIActionTime, selectedFolder])

    const FolderPath = ({ folder, setSelectedFolder }) => {
        if (!folder) return null; // Base case for recursion
      
        return (
          <React.Fragment>
            {/* Recursive call for parent folder if it exists */}
            {folder.parents && <FolderPath folder={folder.parents} setSelectedFolder={setSelectedFolder} />}
            <p className="text-muted me-2">/</p>
            <div className="me-2">
              <Link to="#" className="text-muted" onClick={() => setSelectedFolder(folder)}>
                {folder.name}
              </Link>
            </div>
          </React.Fragment>
        );
    };

    const searchDirectory = async(value) => {
        setLoading(true);
        if(value.length > 0){
            const response = await searchDirectoryRequest({ Parent: selectedFolder?.id, SearchQuery: value, SelectMode: selectMode });
            setFolders(response?.folders || []);
            setImages(response?.images || []);
        } else {
            const response = await getDirectoryRequest({ Parent: selectedFolder?.id, SelectMode: selectMode });
            setFolders(response?.folders || []);
            setImages(response?.images || []);
        }
        setLoading(false);
    }

    const ItemCard = ({ item }) => (
        <Col xl={2} lg={3} md={4} sm={6} xs={12}>
            <Card className="shadow-none border">
                <CardBody className="p-3">
                <div className="">
                    <div className="float-end ms-2">
                        <UncontrolledDropdown className="mb-2" direction="left">
                            <DropdownToggle color="white" className="btn btn-link text-muted mt-n2"><i className="mdi mdi-dots-horizontal"></i></DropdownToggle>
                            <DropdownMenu>
                                {item.type === "Folder" ? (
                                    <Link className="dropdown-item" to="#" onClick={()=>setSelectedFolder(item)}>Open</Link>
                                ): (
                                    item.ready && <Link className="dropdown-item" to="#" onClick={()=>toggleImageViewModal({ row: item })}>Open</Link>
                                )}
                                {!selectMode ? (
                                    <React.Fragment>
                                        {item.type === "Folder" ? (
                                            <Link className="dropdown-item" to="#" onClick={()=>toggleFolderModal({ row: item, mode: "Update" })}>Rename</Link>
                                        ) : (
                                            <Link className="dropdown-item" to="#" onClick={()=>toggleImageModal({ row: item, mode: "Update" })}>Edit</Link>
                                        )}

                                        <div className="dropdown-divider"></div>

                                        {item.type === "Folder" ? (
                                            <Link className="dropdown-item" to="#" onClick={()=>toggleConfirmModal({ row: item, request: deleteFolderRequest, body: "This will delete all contents within the folder also!" })}>Delete</Link>
                                        ) : (
                                            <Link className="dropdown-item" to="#" onClick={()=>toggleConfirmModal({ row: item, request: deleteImageRequest })}>Delete</Link>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    item.type !== "Folder" && <Link className="dropdown-item" to="#" onClick={()=>{
                                        if(selectedID === item?.id){
                                            notify({status: "error", message: "Already Selected"})
                                        } else {
                                            setSelectedID(item?.id)
                                        }
                                    }}>Select</Link>
                                )}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                    <div style={{cursor: 'pointer'}} onClick={()=>{ if(item.type === "Folder"){ setSelectedFolder(item); } else { 
                        if(selectMode){
                            if(selectedID === item?.id){
                                notify({status: "error", message: "Already Selected"})
                            } else {
                                setSelectedID(item?.id)
                            }
                        } else {
                            toggleImageViewModal({ row: item })
                        }
                    }}}>
                        {item.type === "Folder" ? (
                            <div className="avatar-md me-3 mb-3">
                                <div className="avatar-title bg-transparent rounded"><i className="bx bxs-folder font-size-24 text-warning"></i></div>
                            </div>
                        ) : (
                            <div className="avatar-md me-3 mb-3" style={{width:"50%"}}>
                                {item.ready ?
                                    <img alt={item.caption} className="avatar-title bg-transparent rounded" style={{objectFit:'cover'}} src={(process.env.REACT_APP_STORAGE_TYPE === 'external' ? process.env.REACT_APP_STORAGE_URL : process.env.REACT_APP_API_PUBLIC_URL)+(item.variants?.find(v=>v.variant === 'Hero-Featured')?.file || item.file)} />
                                        : 
                                    <div style={{position:'relative'}}>
                                        <img alt={item.caption} className="avatar-title bg-transparent rounded" style={{objectFit:'cover',opacity:0.4}} src={process.env.REACT_APP_API_PUBLIC_URL+(item.file)} />
                                        <div className="avatar-title bg-transparent rounded" style={{objectFit:'cover', backgroundColor: 'rgba(0,0,0,0.1)', position:'absolute',top:0}}><i className="bx bx-loader bx-spin font-size-24 text-primary"></i></div>
                                    </div>
                                }
                            </div>
                        )}
                        <div className="d-flex">
                            <div className="overflow-hidden me-auto">
                                <h5 className="font-size-14 text-truncate mb-1"><Link to="#" className="text-body">{item.name}</Link></h5>
                                {item.type === "Folder" ? <p className="text-muted text-truncate mb-0">{item.immediate_child_count > 0 ? item.immediate_child_count+" Items" : "Empty"}</p> : <p className="text-muted text-truncate mb-0">-</p>}
                            </div>
                        </div>
                    </div>
                </div>
                </CardBody>
            </Card>
        </Col>
    )

    return (
        <React.Fragment>
            {!selectMode && <DynamicModal config={folderModalConfig} toggleModal={toggleFolderModal} submitModes={folderFormModes} ds={folderDS} disableSockets={true} />}
            {!selectMode && <DynamicModal config={imageModalConfig} toggleModal={toggleImageModal} submitModes={imageFormModes} ds={imageDS} disableSockets={true} />}
            {!selectMode && <ConfirmDialog id={confirmID} params={confirmParams} config={confirmConfig} visible={confirmModalVisible} toggleModal={toggleConfirmModal} />}
            <Modal size="xl" isOpen={imageViewModalVisible} toggle={()=>toggleImageViewModal({})}>
                <div className="modal-header">
                    <button type="button" onClick={() => toggleImageViewModal({})} className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <React.Fragment>
                    <div id="modalBody" className="modal-body">
                        <div className="text-center">
                            {selectedImage && selectedImage?.variants.map((v,k)=>
                                <button key={k} onClick={()=>setSelectedVariant(v)} className={"btn btn-rounded me-2 mb-2" + (selectedVariant?.variant === v.variant ? " btn-primary" : " btn-secondary")}>{v.variant}</button>
                            )}
                        </div>
                        <hr />
                        <div className="text-center">
                            <img alt={selectedImage?.caption} className="img-fluid" src={(process.env.REACT_APP_STORAGE_TYPE === 'external' ? process.env.REACT_APP_STORAGE_URL : process.env.REACT_APP_API_PUBLIC_URL)+(selectedVariant ? selectedVariant?.file : selectedImage?.file)} />
                        </div>
                    </div>
                </React.Fragment>
            </Modal>
            <Loader loading={loading} />
            {!selectMode &&
            <div>
                <Row className="mb-3">
                    <Col xl={3} sm={6}>
                        <div className="mt-2"><h5>Media Library</h5></div>
                    </Col>
                    <Col xl={9} sm={6}>
                        <Form className="mt-4 mt-sm-0 float-sm-end d-flex align-items-center" onSubmit={(e)=>e.preventDefault()}>
                            <UncontrolledDropdown className="mb-0" direction="right">
                                <DropdownToggle color="white" className="btn btn-link text-muted mt-n2"><i className="mdi mdi-dots-vertical font-size-20"></i></DropdownToggle>
                                <DropdownMenu>
                                    <Link className="dropdown-item" to="#" onClick={()=>toggleFolderModal({ row: { Parent: selectedFolder?.id }, mode: "Create" })}>New Folder</Link>
                                    {/* {selectedFolder && <Link className="dropdown-item" to="#" onClick={()=>toggleImageModal({ row: { Parent: selectedFolder?.id }, mode: "Create", modeKey: 0, size: "md" })}>New Image</Link>} */}
                                    {selectedFolder && <Link className="dropdown-item" to="#" onClick={()=>toggleImageModal({ row: { Parent: selectedFolder?.id }, mode: "Create", modeKey: 1, size: "xl" })}>New Images</Link>}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Form>
                    </Col>
                </Row>
            </div>}
            <div>
                <Row>
                    {selectMode && suggestedImages.length > 0 && <Col xs={12}>
                        <h5>Suggested Images</h5>
                        <Row>
                            {[...suggestedImages.map(image => ({ ...image, type: 'Image' }))].map((item, key) => (
                                <ItemCard key={key} item={item} />
                            ))}
                        </Row>
                        <hr />
                    </Col>}
                    <Col xs={12} sm={6}>
                        <div className="d-flex flex-wrap">
                            <div className="me-2">
                                <Link to="#" className="text-muted" onClick={()=>{
                                    searchDirectory(searchValue)
                                }}><i className="mdi mdi-refresh"></i></Link> - 
                            </div>
                            <div className="me-2">
                                <Link to="#" className="text-muted" onClick={()=>{
                                    if(selectedFolder){
                                        setSelectedFolder(null)
                                    } else {
                                        searchDirectory("")
                                    }
                                }}><i className="mdi mdi-home"></i></Link>
                            </div>
                            {selectedFolder && <FolderPath folder={selectedFolder} setSelectedFolder={setSelectedFolder} />}
                        </div>
                    </Col>
                    <Col xs={12} sm={6} className="float-sm-end justify-content-end d-flex align-items-center">
                        <div className="search-box mb-2 me-2">
                            <div className="position-relative">
                                <input type="text" className="form-control bg-light border-light rounded" value={searchValue} placeholder="Search..." onKeyUp={(e)=>e.key === 'Enter' && searchDirectory(searchValue)} onChange={(e)=>setSearchValue(e.target.value)} />
                                <i className="bx bx-search-alt search-icon"></i>
                            </div>
                        </div>
                        <button type="button" className="btn btn-sm btn-primary ms-2" onClick={() => searchDirectory(searchValue)}>Find</button>
                    </Col>
                    {folders.length + images.length === 0 && <h3 className="text-center w-100">No items found</h3>}
                    {[...folders.map(folder => ({ ...folder, type: 'Folder' })), ...images.map(image => ({ ...image, type: 'Image' }))].map((item, key) => (
                        <ItemCard key={key} item={item} />
                    ))}
                </Row>
            </div>
        </React.Fragment>
        
    )
    
}

export default MediaLibrary;

