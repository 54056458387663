
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import React, { useEffect, useState } from "react";
import { handleResponse } from "../../custom/helpers/HandleResponse";
import { exportDataListRequest, getAuthorsRequest } from "../../api/controller/ReportController";
import Select from "react-select"
import Flatpickr from "react-flatpickr"
import moment from "moment";

const ArticleReports = () => {

    const [authors, setAuthors] = useState([{ label: "All", value: 0 }]);

    const [selectedPeriod, setSelectedPeriod] = useState([moment().subtract(1, 'month').format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")])
    const [selectedAuthor, setSelectedAuthor] = useState({ label: "All", value: 0 });

    useEffect(() => {
        async function getSelects(){
            const response = await getAuthorsRequest();
            if(response.data){
                setAuthors([{ label: "All", value: 0 }, ...response.data.map(i=>({ label: i.name, value: i.id }))])
            } else {
                handleResponse(response);
            }
        }
        getSelects()
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <h3>Reports</h3>
                    <Card>
                        <CardBody style={{minHeight:"750px"}}>
                            <Row>
                                <Col xs={12} md={6} className="mb-3">
                                    <Label>Period</Label>
                                    <Flatpickr onChange={(_,e) => (e.split(" to ").length > 1) ? setSelectedPeriod(e.split(" to ")) : setSelectedPeriod([])} placeholder="Select..." options={{ mode:'range', altInput: true }} value={selectedPeriod} className="form-control d-block" />
                                </Col>
                                <Col xs={12} md={6} className="mb-3">
                                    <Label>Author</Label>
                                    <Select onChange={(e) => setSelectedAuthor(e)} placeholder="Select..." options={authors} value={selectedAuthor} />
                                </Col>
                                <Col xs={12}>
                                    <button className="btn btn-primary" onClick={async()=>{
                                        const downloadName = `Report ${moment(selectedPeriod[0]).format("YYYY-MM-DD")} to ${moment(selectedPeriod[1]).format("YYYY-MM-DD")}.xlsx`
                                        const response = await exportDataListRequest({ Period: selectedPeriod, Author: selectedAuthor?.value, DownloadName: downloadName })
                                        handleResponse(response)
                                    }}>Generate <i className="mdi mdi-download"></i></button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
        
    )
    
}

export default ArticleReports;

