import http from "../core/http";
const group = 'articles';

export const getDataListRequest =  async (data) => {
    return await http.post(`${group}/get`, data).then(res => res.data).catch(http.defaultCatch);
}

export const isDisplayedRequest =  async (data) => {
    return await http.post(`${group}/isDisplayed`, data).then(res => res.data).catch(http.defaultCatch);
}

export const findDataRequest =  async (data) => {
    return await http.post(`${group}/find`, data).then(res => res.data).catch(http.defaultCatch);
}

export const createDataRequest =  async (data) => {
    return await http.post(`${group}/create`, data).then(res => res.data).catch(http.defaultCatch);
}

export const updateDataRequest =  async (data) => {
    return await http.post(`${group}/update`, data).then(res => res.data).catch(http.defaultCatch);
}

export const deleteDataRequest =  async (data) => {
    return await http.post(`${group}/delete`, data).then(res => res.data).catch(http.defaultCatch);
}

export const getCategoryListRequest =  async (data) => {
	return await http.post(`${group}/getCategoryList`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getTagListRequest =  async (data) => {
	return await http.post(`${group}/getTagList`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getFeatureLocationListRequest =  async (data) => {
	return await http.post(`${group}/getFeatureLocationList`, data).then(res => res.data).catch(http.defaultCatch);
}


export const sendToCopyDeskRequest =  async (data) => {
    return await http.post(`${group}/sendToCopyDesk`, data).then(res => res.data).catch(http.defaultCatch);
}
export const selfAssignEditorRequest =  async (data) => {
    return await http.post(`${group}/selfAssignEditor`, data).then(res => res.data).catch(http.defaultCatch);
}
export const lockArticleRequest =  async (data) => {
    return await http.post(`${group}/lockArticle`, data).then(res => res.data).catch(http.defaultCatch);
}
export const unlockArticleRequest =  async (data) => {
    return await http.post(`${group}/unlockArticle`, data).then(res => res.data).catch(http.defaultCatch);
}
export const scheduleRequest =  async (data) => {
    return await http.post(`${group}/schedule`, data).then(res => res.data).catch(http.defaultCatch);
}
export const publishRequest =  async (data) => {
    return await http.post(`${group}/publish`, data).then(res => res.data).catch(http.defaultCatch);
}
export const archiveRequest =  async (data) => {
    return await http.post(`${group}/archive`, data).then(res => res.data).catch(http.defaultCatch);
}
export const featureRequest =  async (data) => {
    return await http.post(`${group}/feature`, data).then(res => res.data).catch(http.defaultCatch);
}

export const mceUploadRequest =  async (data) => {
    return await http.post(`${group}/mceUpload`, data).then(res => res.data).catch(http.defaultCatch);
}
